/* --- Global --- */
import {Helmet} from 'react-helmet';
import wave from '@assets/images/bump.png';

import {ArticlePreview} from '@queries';
/* --- Local --- */

/* --- Component --- */
const PageHome = props => {
  return (
    <>
      <SearchEngineOptimization />
      <Featured />
    </>
  );
};

const Featured = props => {
  return (
    <Atom.Box>
      <Atom.Container
        sx={{
          py: 5,
          p: 4,
          maxWidth: ['100%', '100%', 550, 700],
          zIndex: 1000,
        }}>
        <ArticlePreview />
      </Atom.Container>
    </Atom.Box>
  );
};

const SearchEngineOptimization = props => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Hire Me - Fullstack Javascript/Web3 Engineer</title>
      <meta property="og:url" content="http://ethquest.io" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Hire Me - Fullstack Javascript/Web3 Enginee"
      />
      <meta
        property="og:description"
        content="Ready to start a new project? Let's get the conversation started."
      />
    </Helmet>
  );
};

export default PageHome;
