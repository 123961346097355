/* --- Global --- */
import {StaticQuery, graphql} from 'gatsby';
import {QuestCard} from '@components';
/* --- Component --- */
const QuestPreview = () => (
  <StaticQuery
    query={graphql`
      {
        contentCore: allFile(
          filter: {sourceInstanceName: {eq: "content-quest"}}
        ) {
          edges {
            node {
              id
              relativeDirectory
              childMdx {
                excerpt
                body
                frontmatter {
                  alias
                  title
                  subtitle
                  summary
                  image
                  featured
                }
              }
            }
          }
        }
      }
    `}
    render={data =>
      data.contentCore.edges.map(({node}) => (
        <Atom.Box sx={styles.card}>
          <Atom.Flex sx={styles.cover}>
            <Atom.BackgroundImage
              // src="https://images.squarespace-cdn.com/content/v1/5ce6c5ea16bcf700010cf4eb/1569085618224-15UT48EN1VTSFWXOHFA0/ke17ZwdGBToddI8pDm48kKAwwdAfKsTlKsCcElEApLR7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UegTYNQkRo-Jk4EWsyBNhwKrKLo5CceA1-Tdpfgyxoog5ck0MD3_q0rY3jFJjjoLbQ/ethereal-ny-home-kevin-owocki.jpg?format=2500w"
              sx={styles.featured}
            />
            <Atom.Absolute sx={{top: 0, right: 0, m: -3}}>
              <Atom.Flex center column sx={styles.badgeContainer}>
                <Atom.Image
                  sx={styles.badge}
                  src={node.childMdx.frontmatter.image}
                />
              </Atom.Flex>
            </Atom.Absolute>
            <Atom.Box sx={styles.content}>
              <Atom.Heading lg>{node.childMdx.frontmatter.title}</Atom.Heading>
              <Atom.Paragraph sm>
                {node.childMdx.frontmatter.title}
              </Atom.Paragraph>
            </Atom.Box>
          </Atom.Flex>
          <Atom.Flex column card sx={styles.main}>
            <Atom.Paragraph sx={{fontSize: [1, 1]}}>
              {node.childMdx.frontmatter.summary}
            </Atom.Paragraph>
            <Atom.Box sx={styles.buttonContainer}>
              <Molecule.Link to={`/quest/${node.childMdx.frontmatter.alias}`}>
                <Atom.Button sx={styles.button}>Learn More</Atom.Button>
              </Molecule.Link>
            </Atom.Box>
          </Atom.Flex>
        </Atom.Box>
      ))
    }
  />
);

const styles = {
  card: {
    mx: [0, 0, '1.5%'],
    width: ['100%', '100%', '30%'],
  },
  cover: {
    bg: 'smoke',
    color: 'charcoal',
    pt: 5,
    px: 3,
  },
  coverImage: {
    opacity: 0.6,
  },
  buttonContainer: {
    justifySelf: 'flex-end',
    mt: 'auto',
  },
  content: {
    alignSelf: 'flex-end',
    mt: 'auto',
  },
  badgeContainer: {
    bg: 'white',
    borderRadius: 99999,
    overflow: 'hidden',
    boxShadow: 1,
    height: 80,
    width: 80,
    p: 2,
  },
  badge: {
    // width: 64,
    width: '70%',
  },
  button: {
    bg: 'blue',
    width: '100%',
    mt: 3,
  },
};

export const QuestCardItem = ({
  title,
  subtitle,
  summary,
  image,
  label = 'View Quest',
  action,
  ...props
}) => {
  return (
    <Atom.Box sx={props.sx}>
      <Atom.Flex sx={styles.cover}>
        <Atom.BackgroundImage
          // src="https://images.squarespace-cdn.com/content/v1/5ce6c5ea16bcf700010cf4eb/1569085618224-15UT48EN1VTSFWXOHFA0/ke17ZwdGBToddI8pDm48kKAwwdAfKsTlKsCcElEApLR7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UegTYNQkRo-Jk4EWsyBNhwKrKLo5CceA1-Tdpfgyxoog5ck0MD3_q0rY3jFJjjoLbQ/ethereal-ny-home-kevin-owocki.jpg?format=2500w"
          sx={styles.coverImage}
        />
        <Atom.Absolute sx={{top: 0, right: 0, m: -3}}>
          <Atom.Flex center column sx={styles.badgeContainer}>
            <Atom.Image sx={styles.badge} src={image} />
          </Atom.Flex>
        </Atom.Absolute>
        <Atom.Box sx={styles.content}>
          <Atom.Heading lg>{title}</Atom.Heading>
          <Atom.Paragraph sm>{subtitle}</Atom.Paragraph>
        </Atom.Box>
      </Atom.Flex>
      <Atom.Flex column card sx={props.main}>
        <Atom.Paragraph sx={{fontSize: [1, 1]}}>{summary}</Atom.Paragraph>
        <Atom.Box sx={styles.buttonContainer}>
          <Molecule.Link to={`/quest/${props.adventure}/${props.id}`}>
            <Atom.Button sx={styles.button}>{label}</Atom.Button>
          </Molecule.Link>
        </Atom.Box>
      </Atom.Flex>
    </Atom.Box>
  );
};

export default QuestPreview;
